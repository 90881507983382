<template>
  <div>
    <i-table
      :title="$tc('audit', 2)"
      :headers="headers"
      :items="rows"
      dontCreate
      dontEdit
    >
    </i-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logs: [],
      reload: false,
      dialog: false,
      toEdit: null,
      columns: [
        { label: this.$tc('model'), field: 'content_type.app_label' },
        { label: this.$tc('object_repr'), field: 'object_repr' },
        { label: this.$t('message'), field: 'changes' },
        { label: this.$t('actor'), field: 'actor' },
        { label: this.$tc('date'), field: 'timestamp' },
        { label: this.$tc('additional_data'), field: 'additional_data' },
        { label: this.$tc('ip_addr'), field: 'remote_addr' },
        { label: this.$t('action'), field: 'action' }
      ],
      rows: [],
      total: 0,
      next: null,
      prev: null,
      loading: false,
      audit_values: {},
      audit_appr: {},
      perPage: 10,
      page: 1,
      action_flag: {
        0: this.$t('updated'),
        1: this.$t('added'),
        2: this.$t('updated'),
        3: this.$t('deleted'),
        4: this.$t('login'),
        5: this.$t('logout'),
        6: this.$t('login_failed')
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('model'),
          value: 'content_type'
        },
        {
          text: this.$tc('object_repr'),
          value: 'object_repr'
        },
        {
          text: this.$t('message'),
          value: 'changes'
        },
        {
          text: this.$t('actor'),
          value: 'actor'
        },
        {
          text: this.$t('date'),
          value: 'timestamp'
        },
        {
          text: this.$t('additional_data'),
          value: 'additional_data'
        },
        {
          text: this.$t('ip_addr'),
          value: 'remote_addr'
        },
        {
          text: this.$t('action'),
          value: 'action'
        }
      ]
    }
  },
  methods: {
    getLogs() {
      this.rows = []
      this.loading = true
      this.$api.dashboard
        .logs({})
        .then((response) => {
          for (let log of response.data) {
            let changes = ''
            let fields = ''
            if (log.action < 2) {
              changes += this.$t('add_fields') + ' [\n'
              for (let [field, value] of Object.entries(
                JSON.parse(log.changes)
              )) {
                fields += '**' + field + '** : ' + value[1] + '\n'
              }
            } else if (log.action > 1) {
              changes += this.$t('fields_changed') + ' [\n'
              for (let [field, value] of Object.entries(
                JSON.parse(log.changes)
              )) {
                fields +=
                  '**' +
                  field +
                  '** : ' +
                  this.$t('before') +
                  ': ' +
                  value[0] +
                  ', ' +
                  this.$t('change_to') +
                  ' : ' +
                  value[1] +
                  '\n'
              }
            }
            let row = {
              content_type: log.content_type.app_label,
              object_repr: log.object_repr,
              changes:
                changes != '' ? changes + fields + ']' : this.$t('no_changes'),
              actor:
                log.actor != null
                  ? log.actor.username
                  : this.$t('audit_system'),
              timestamp: this.$moment(log.timestamp).format(
                'DD/MM/YYYY - HH:mm:ss'
              ),
              additional_data:
                log.additional_data != null
                  ? JSON.stringify(log.additional_data)
                  : this.$t('no_addi_data'),
              remote_addr: log.remote_addr,
              action: this.action_flag[log.action]
            }
            this.rows.push(row)
          }
        })
        .finally(() => {
          if (this.$store.getters.getIsloading) {
            this.$store.dispatch('utils/actionLoading')
          }
          this.loading = false
        })
    }
  },
  mounted() {
    this.getLogs()
  }
}
</script>
